import { ChainId, Trade, TradeType } from 'flashliquidity-sdk'
import React, { useContext, useState } from 'react'
import { /**styled,*/ ThemeContext } from 'styled-components/macro'
import { Text } from 'rebass'
import { useActiveWeb3React } from '../../hooks'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE /** , ExternalLink*/ } from '../../theme'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown
} from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'
import TradePrice from './TradePrice'

/**const InfoLink = styled(ExternalLink)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.bg3};
  padding: 6px 6px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
`*/

function TradeSummary({
  chainId,
  trade,
  allowedSlippage
}: {
  chainId: ChainId
  trade: Trade
  allowedSlippage: number
}) {
  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee } = computeTradePriceBreakdown(chainId, trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    chainId,
    trade,
    allowedSlippage
  )
  const [showInverted, setShowInverted] = useState<boolean>(false)

  return (
    <>
      <AutoColumn style={{ padding: '0 20px' }}>
        <RowBetween>
          <Text fontWeight={500} fontSize={12} color={theme.text2}>
            Price
          </Text>
          <TradePrice
            price={trade?.executionPrice}
            showInverted={showInverted}
            setShowInverted={setShowInverted}
          />
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
              {isExactIn ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
            <TYPE.black color={theme.text1} fontSize={12}>
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${
                    trade.outputAmount.currency.symbol
                  }` ?? '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${
                    trade.inputAmount.currency.symbol
                  }` ?? '-'}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
              Price Impact
            </TYPE.black>
            <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        {/*<RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Liquidity Provider Fee
            </TYPE.black>
            <QuestionHelper text="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive." />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
          </TYPE.black>
        </RowBetween>*/}
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary
            chainId={chainId!}
            trade={trade}
            allowedSlippage={allowedSlippage}
          />
          {showRoute && (
            <>
              <AutoColumn style={{ padding: '0 24px' }}>
                <RowFixed>
                  <TYPE.black
                    fontSize={12}
                    fontWeight={400}
                    color={theme.text2}
                  >
                    Route
                  </TYPE.black>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </RowFixed>
                <SwapRoute chainId={chainId!} trade={trade} />
              </AutoColumn>
            </>
          )}
          {/*<AutoColumn style={{ padding: '0 24px' }}>
            <InfoLink href={'https://uniswap.info/pair/' + trade.route.pairs[0].liquidityToken.address} target="_blank">
              View pair analytics ↗
            </InfoLink>
          </AutoColumn>*/}
        </>
      )}
    </AutoColumn>
  )
}
