import { ChainId } from 'flashliquidity-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.SEPOLIA]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.ETHEREUM]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.BASE_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.BASE]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.MUMBAI]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.MATIC]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.ZKEVM_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.ZKEVM]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.FUJI]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.AVALANCHE]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.ARBITRUM_TESTNET]: '0x695Efd4a19F1f900ba14ACEAFD6Dea3738Cde791',
  [ChainId.ARBITRUM]: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
